<template lang="pug">
.card
  .card-body
    b-table(
      :items="users"
      :fields="fields"
      empty-text="No users available"
      show-empty
      )
      template(v-slot:cell(username)="data")
        span {{data.item.first_name.slice(0,1)}}. {{data.item.last_name}}
        span.small <br> 
          strong {{data.item.company_assigned_name}}
        span.small <br> 
          i(style="color: gray") {{data.item.role}}

      template(v-slot:cell(jobs_assigned)="row")
        //- span(style="color: green; font-weight: bold") 
        b-button.w-100(variant="outline-success" size="sm" v-if="!isEmployee(users[row.index].role)" @click="row.toggleDetails") {{row.item.responsibilities.length}}
      
      template(v-slot:cell(company_create)="row")
        b-form-checkbox.company(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.create_company" 
          @change="updatePrivilege(row, 'create_company', $event)"
        )

      template(v-slot:cell(company_edit)="row")
        b-form-checkbox.company(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.edit_company" 
          @change="updatePrivilege(row, 'edit_company', $event)"
        )
      
      template(v-slot:cell(company_toggle)="row")
        b-form-checkbox.company(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.toggle_company" 
          @change="updatePrivilege(row, 'toggle_company', $event)"
        )

      template(v-slot:cell(project_create)="row")
        b-form-checkbox.project(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.create_project" 
          @change="updatePrivilege(row, 'create_project', $event)"
        )

      template(v-slot:cell(project_edit)="row")
        b-form-checkbox.project(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.edit_project" 
          @change="updatePrivilege(row, 'edit_project', $event)"
        )

      template(v-slot:cell(project_toggle)="row")
        b-form-checkbox.project(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.toggle_project" 
          @change="updatePrivilege(row, 'toggle_project', $event)"
        )
      
      template(v-slot:cell(job_create)="row")
        b-form-checkbox.job(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.create_job" 
          @change="updatePrivilege(row, 'create_job', $event)"
        )

      template(v-slot:cell(job_edit)="row")
        b-form-checkbox.job(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.edit_job" 
          @change="updatePrivilege(row, 'edit_job', $event)"
        )

      template(v-slot:cell(job_toggle)="row")
        b-form-checkbox.job(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.toggle_job" 
          @change="updatePrivilege(row, 'toggle_job', $event)"
        )

      template(v-slot:cell(accept_reject)="row")
        b-form-checkbox.accept-reject(
          v-if="!isEmployee(users[row.index].role)"
          :checked="users[row.index].privileges.accept_reject" 
          @change="updatePrivilege(row, 'accept_reject', $event)"
        )
      
      template(slot="row-details" slot-scope="row")
        Responsibilities(
          :user="users[row.index]"
          :companies="companies"
          @reload="fetchUsers"
        )

    b-button(variant="success" @click="showModalAddUser = true") + Add user
    b-button.ml-2(variant="success" v-show='userAccount.userRole === "company_admin"' @click="showModalImportUsers = true") + Import users

    b-modal(
      v-model="showModalAddUser"
      title="Add new user"
      size="md"
      hide-footer
    )
      .row
        .col-6
          b-input(type="text" v-model="newUser.first_name" placeholder="Enter first name")
        .col-6
          b-input(type="text" v-model="newUser.last_name" placeholder="Enter last name")
      .row.mt-2
        .col
          multiselect(
            v-model="newUser.company",
            deselect-label="Unselect",
            track-by="name",
            label="name",
            placeholder="Select company",
            :options="companies",
            :searchable="true",
            :allow-empty="true"
            :preselect-first="companies.length === 1"
          )
      .row.mt-2
        .col
          multiselect(
            v-model="newUser.role",
            deselect-label="Unselect",
            placeholder="Select role",
            :options="['candidate','recruiter','company_admin']",
            :allow-empty="true"
          )
      .row.mt-2
        .col-12
          b-input(type="email" v-model="newUser.email" placeholder="Enter user email")
      .row.mt-2
        .col-12
          b-input(type="text" v-model="newUser.password" placeholder="Enter user password")
      .row.mt-2
        .col
          p User will be able to immediately sign in. Please send him credentials manually.
          b-button.w-100(
            :disabled="newUserSubmitted"
            type="button"
            variant="success"
            @click="addUser"
          ) {{newUserSubmitted ? 'User added' : 'Add user'}}

    b-modal(
      v-model="showModalImportUsers"
      title="Import users"
      size="md"
      hide-footer
    )
     b-form-file.mb-2(v-model='file' :state='Boolean(file)' accept='.csv' placeholder='Choose a file or drop it here...' drop-placeholder='Drop file here...')
     .row
      .col-12
        p Please provide a csv file in following format: 
        p email, firstname, lastname, role as one of: {{['candidate','recruiter','company_admin'].join(', ')}}
     .row
      .col-6
        b-button(:disabled="!Boolean(file)" type="button" variant="success" @click="importUsers") Import 
        b-button.ml-2(type="button" variant="outline-secondary" @click="showModalImportUsers = false; file = null;") Cancel 

</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import Multiselect from 'vue-multiselect'
import { Responsibilities } from './components'
import { STORE_MODULE } from '@/enums'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default {
    name: 'Users',

    components: {
        Multiselect,
        Responsibilities,
    },

    computed: {
        ...mapState(['userAccount']),
    },

    watch: {
        userAccount: {
            handler(userAccount) {
                if (!!userAccount) {
                    this.fetchUsers()
                    this.fetchCompanies()
                }
            },
            immediate: true,
        },

        showModalAddUser() {
            if (this.showModalAddUser === false) {
                this.newUserSubmitted = false
                this.newUser = {
                    email: '',
                    password: '',
                    first_name: '',
                    last_name: '',
                    company: '',
                }
            }
        },
    },

    data() {
        return {
            companies: [],

            showModalAddUser: false,
            newUserSubmitted: false,
            newUser: {
                email: '',
                password: '',
                first_name: '',
                last_name: '',
                company: '',
                role: '',
            },

            users: [],
            jobsChecked: [],

            fields: [
                { key: 'username' },
                { key: 'jobs_assigned' },
                { key: 'company_create', label: 'Company setup' },
                { key: 'company_edit' },
                { key: 'project_create', label: 'Department create' },
                { key: 'project_edit', label: 'Department edit' },
                { key: 'project_toggle', label: 'Department toggle' },
                { key: 'job_create' },
                { key: 'job_edit' },
                { key: 'job_toggle' },
                { key: 'accept_reject' },
            ],

            file: null,
            showModalImportUsers: false,
        }
    },

    methods: {
        addUser() {
            this.$store.state.backend
                .post('/auth/signup', {
                    ...this.newUser,
                    company_assigned: this.newUser.company.id,
                    status: 'accepted',
                    invited_by: this.userAccount.id,
                })
                .then((res) => {
                    this.newUserSubmitted = true
                    setTimeout(() => {
                        this.showModalAddUser = false
                        this.fetchUsers()
                    }, 2000)
                })
                .catch((err) => alert(err))
        },

        fetchUsers() {
            this.$store.state.backend.get('/admin/users').then((res) => {
                this.users = res.data.users
            })
        },

        isEmployee(role) {
            return false
        },

        importUsers() {
            const reader = new FileReader()
            reader.readAsText(this.file)
            reader.onload = () => {
                if (reader.result.length == 0) {
                    alert('File is empty')
                    return
                }
                const lines = reader.result.split(/[\r\n]+/g).filter(function (i) {
                    return i
                })

                if (lines.length == 0) {
                    alert('No users to import')
                    return
                }

                const users = lines.map((line) => {
                    const userdata = line.split(',')

                    if (userdata.length == 4) {
                        return {
                            email: userdata[0].trim(),
                            first_name: userdata[1].trim(),
                            last_name: userdata[2].trim(),
                            role: userdata[3].trim(),
                        }
                    } else return null
                })

                const filteredUsers = users.filter(function (user) {
                    const isEmpty = (str) => {
                        return !str || 0 === str.length
                    }

                    return (
                        user != null &&
                        !isEmpty(user.email) &&
                        !isEmpty(user.first_name) &&
                        !isEmpty(user.last_name) &&
                        !isEmpty(user.role)
                    )
                })

                if (filteredUsers.length == 0) {
                    alert('No users to import')
                    return
                }

                this.$store.state.backend
                    .post('/auth/import-users', request)
                    .then((res) => {
                        this.showModalImportUsers = false
                        this.file = null
                        alert(res.data.message)
                        setTimeout(() => {
                            this.fetchUsers()
                        }, 2000)
                    })
                    .catch((err) => alert(err))
            }
        },
        fetchCompanies(resolve) {
            this.$store.state.backend
                .get('/jobBuilder/company/all')
                .then((ret) => (this.companies = ret.data))
                .catch((error) => alert(error))
        },

        updatePrivilege(row, privilege, value) {
            const user = this.users[row.index]
            user.privileges[row.field.key] = value
            const data = { user_id: user.id, [privilege]: value }

            this.$store.state.backend
                .post('/admin/privileges', data)
                .catch((error) => alert(error))
        },
    },
}
</script>
<style lang="sass">
.company .custom-control-input:checked ~ label::before
  background-color: red !important

.project .custom-control-input:checked ~ label::before
  background-color: orange !important

.job .custom-control-input:checked ~ label::before
  background-color: #42bff4 !important

.accept-reject .custom-control-input:checked ~ label::before
  background-color: green !important
</style>
