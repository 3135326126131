<template lang="pug">
div
  .row.mt-2(v-if="userAccount.userRole === 'admin'")
    .col-5
      b-form-group(
        label="Assigned to:"
        label-cols-sm='3'
      )
        multiselect(
          v-model="reassignToCompany",
          deselect-label="Unselect",
          track-by="id",
          label="name",
          placeholder="Select company",
          :options="companies",
          :searchable="true",
          :allow-empty="true"
          :disabled="reassignLocked"
        )
    .col-1
      b-button(
        variant="outline-danger" 
        size="sm"
        @click="reassignLocked = !reassignLocked"
      ) {{reassignLocked ? 'Unlock' : 'Lock'}}
    .col-2 
      b-button(
        v-if="user.company_assigned !== reassignToCompany.id"
        variant="success" 
        size="sm"
        @click="showModalReassignCompany = true"
      ) Confirm
      b-modal(
        v-model="showModalReassignCompany"
        title="Confirmation"
        hide-footer
      )
        p You are about to reassign user to:
        p <b>{{reassignToCompany.name}}</b> 
        p If you do so - ALL the previous responsibilities will be deleted from database.
        p Do you want to continue?
        .row
          .col-6
            b-button(variant="success" @click="reassignCompany") Yes, proceed
            b-button.ml-2(variant="outline-secondary" @click="showModalReassignCompany = false") Cancel

  .row
    .col-12
      h5.text-center(style="color: #42bff4") Responsibilites
    template(v-for="(project, key) in departments") 
      .col-12.mt-3(v-if="project.jobs.length !== 0")
        //- b-form-checkbox
        hr(style="background-color: #42bff4")
        b(style="color: #42bff4; font-size: 18px") {{key}}
        //- div(v-if="project.jobs.length !== 0")
        b-form-checkbox(
          v-for="(job, key, index) in project.jobs"
          :key="job.job_id"
          :checked="userData.responsibilities.includes(job.job_id)"
          @change="updateResps(job)"
        ) {{job.job_title}}
            //- v-b-tooltip="{title: job ? job.job_description.slice(0,200) + '(...)' : '', position: 'right', delay: {show: 1500, hide: 0}}"
        //- div(v-else)
          p.ml-4
            i 0 active jobs

</template>
<script>
import { STORE_MODULE } from '@/enums'
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default {
    name: 'Responsibilities',

    components: {
        Multiselect,
    },

    props: {
        user: {
            type: Object,
            required: true,
        },
        companies: {
            type: Array,
            required: false,
        },
    },

    mounted() {
        this.loadDepartments(this.user.company_assigned)
    },

    watch: {
        user: {
            handler() {
                if (this.user.company_assigned)
                    this.reassignToCompany = this.companies.filter(
                        (obj) => obj.id === this.user.company_assigned
                    )[0]
            },
            immediate: true,
        },
    },

    data() {
        return {
            userData: this.user,
            departments: {},
            reassignToCompany: {},
            reassignLocked: true,
            showModalReassignCompany: false,
        }
    },

    computed: {
        ...mapState(['userAccount']),
    },

    methods: {
        loadDepartments(company_id) {
            this.$store.state.backend
                .get(`/admin/departments/${company_id}`)
                .then((res) => (this.departments = res.data.departments))
                .catch((err) => alert(err))
        },

        updateResps(job) {
            const resps = this.userData.responsibilities

            resps.includes(job.job_id)
                ? resps.splice(resps.indexOf(job.job_id), 1)
                : resps.push(job.job_id)

            this.$store.state.backend
                .post('/admin/responsibilities', {
                    id: this.userData.id,
                    jobs: resps.join(','),
                })
                .catch((err) => alert(err))
        },

        reassignCompany() {
            this.$store.state.backend
                .post('/admin/reassign-company', {
                    id: this.user.id,
                    company_id: this.reassignToCompany.id,
                })
                .then((res) => {
                    this.loadDepartments(this.reassignToCompany.id)
                    this.showModalReassignCompany = false
                    this.reassignLocked = true
                    this.$emit('reload')
                })
                .catch((err) => alert(err))
        },
    },
}
</script>
